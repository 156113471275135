var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "extra-component-form-wizard-demo" } },
    [
      _vm._m(0),
      _c("form-wizard-number-tab"),
      _c("form-wizard-icon-tab"),
      _c("form-wizard-validation"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-4" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://binarcode.github.io/vue-form-wizard/#/",
            target: "_blank",
            rel: "nofollow",
          },
        },
        [_vm._v("vue-form-wizard")]
      ),
      _vm._v(
        " is a vue based component with no external depenendcies which simplifies tab wizard management and allows you to focus on the functional part of your app rather than wasting time on details."
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }